<template>
  <div class="container">
    <nav class="navbar is-white">
      <div class="container">
        <div class="navbar-brand">
          <a class="navbar-item brand-text" href="../index.html">
            Bulma Admin
          </a>
          <div class="navbar-burger burger" data-target="navMenu">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </div>
        <div id="navMenu" class="navbar-menu">
          <div class="navbar-start">
            <router-link class="navbar-item" to="/">Home</router-link>
            <router-link class="navbar-item" to="/login">Log in</router-link>
            <router-link class="navbar-item" to="/about">About</router-link>
          </div>
        </div>
      </div>
    </nav>
    <!-- END NAV -->
    <div class="container">
      <div class="columns">
        <div class="column is-3">
          <aside class="menu is-hidden-mobile">
            <p class="menu-label">General</p>
            <ul class="menu-list">
              <li><a class="is-active">Dashboard</a></li>
              <li><a>Customers</a></li>
              <li><a>Other</a></li>
            </ul>
            <p class="menu-label">Administration</p>
            <ul class="menu-list">
              <li><a>Team Settings</a></li>
              <li>
                <a>Manage Your Team</a>
                <ul>
                  <li><a>Members</a></li>
                  <li><a>Plugins</a></li>
                  <li><a>Add a member</a></li>
                  <li><a>Remove a member</a></li>
                </ul>
              </li>
              <li><a>Invitations</a></li>
              <li><a>Cloud Storage Environment Settings</a></li>
              <li><a>Authentication</a></li>
              <li><a>Payments</a></li>
            </ul>
            <p class="menu-label">Transactions</p>
            <ul class="menu-list">
              <li><a>Payments</a></li>
              <li><a>Transfers</a></li>
              <li><a>Balance</a></li>
              <li><a>Reports</a></li>
            </ul>
          </aside>
        </div>
        <div class="column is-9">
          <nav class="breadcrumb" aria-label="breadcrumbs">
            <ul>
              <li><a href="../">Bulma</a></li>
              <li><a href="../">Templates</a></li>
              <li><a href="../">Examples</a></li>
              <li class="is-active">
                <a href="#" aria-current="page">Admin</a>
              </li>
            </ul>
          </nav>
          <section class="hero is-info welcome is-small">
            <div class="hero-body">
              <div class="container">
                <h1 class="title">Hello, Admin.</h1>
                <h2 class="subtitle">I hope you are having a great day!</h2>
              </div>
            </div>
          </section>
          <section class="info-tiles">
            <div class="tile is-ancestor has-text-centered">
              <div class="tile is-parent">
                <article class="tile is-child box">
                  <p class="title">439k</p>
                  <p class="subtitle">Users</p>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child box">
                  <p class="title">59k</p>
                  <p class="subtitle">Products</p>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child box">
                  <p class="title">3.4k</p>
                  <p class="subtitle">Open Orders</p>
                </article>
              </div>
              <div class="tile is-parent">
                <article class="tile is-child box">
                  <p class="title">19</p>
                  <p class="subtitle">Exceptions</p>
                </article>
              </div>
            </div>
          </section>
          <div class="columns">
            <div class="column is-6">
              <div class="card events-card">
                <header class="card-header">
                  <p class="card-header-title">Events</p>
                  <a
                    href="#"
                    class="card-header-icon"
                    aria-label="more options"
                  >
                    <span class="icon">
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </a>
                </header>
                <div class="card-table">
                  <div class="content">
                    <table class="table is-fullwidth is-striped">
                      <tbody>
                        <tr>
                          <td width="5%"><i class="fa fa-bell-o"></i></td>
                          <td>Lorum ipsum dolem aire</td>
                          <td class="level-right">
                            <a class="button is-small is-primary" href="#"
                              >Action</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td width="5%"><i class="fa fa-bell-o"></i></td>
                          <td>Lorum ipsum dolem aire</td>
                          <td class="level-right">
                            <a class="button is-small is-primary" href="#"
                              >Action</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td width="5%"><i class="fa fa-bell-o"></i></td>
                          <td>Lorum ipsum dolem aire</td>
                          <td class="level-right">
                            <a class="button is-small is-primary" href="#"
                              >Action</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td width="5%"><i class="fa fa-bell-o"></i></td>
                          <td>Lorum ipsum dolem aire</td>
                          <td class="level-right">
                            <a class="button is-small is-primary" href="#"
                              >Action</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td width="5%"><i class="fa fa-bell-o"></i></td>
                          <td>Lorum ipsum dolem aire</td>
                          <td class="level-right">
                            <a class="button is-small is-primary" href="#"
                              >Action</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td width="5%"><i class="fa fa-bell-o"></i></td>
                          <td>Lorum ipsum dolem aire</td>
                          <td class="level-right">
                            <a class="button is-small is-primary" href="#"
                              >Action</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td width="5%"><i class="fa fa-bell-o"></i></td>
                          <td>Lorum ipsum dolem aire</td>
                          <td class="level-right">
                            <a class="button is-small is-primary" href="#"
                              >Action</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td width="5%"><i class="fa fa-bell-o"></i></td>
                          <td>Lorum ipsum dolem aire</td>
                          <td class="level-right">
                            <a class="button is-small is-primary" href="#"
                              >Action</a
                            >
                          </td>
                        </tr>
                        <tr>
                          <td width="5%"><i class="fa fa-bell-o"></i></td>
                          <td>Lorum ipsum dolem aire</td>
                          <td class="level-right">
                            <a class="button is-small is-primary" href="#"
                              >Action</a
                            >
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                <footer class="card-footer">
                  <a href="#" class="card-footer-item">View All</a>
                </footer>
              </div>
            </div>
            <div class="column is-6">
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">Inventory Search</p>
                  <a
                    href="#"
                    class="card-header-icon"
                    aria-label="more options"
                  >
                    <span class="icon">
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </a>
                </header>
                <div class="card-content">
                  <div class="content">
                    <div class="control has-icons-left has-icons-right">
                      <input
                        class="input is-large"
                        type="text"
                        placeholder=""
                      />
                      <span class="icon is-medium is-left">
                        <i class="fa fa-search"></i>
                      </span>
                      <span class="icon is-medium is-right">
                        <i class="fa fa-check"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card">
                <header class="card-header">
                  <p class="card-header-title">User Search</p>
                  <a
                    href="#"
                    class="card-header-icon"
                    aria-label="more options"
                  >
                    <span class="icon">
                      <i class="fa fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </a>
                </header>
                <div class="card-content">
                  <div class="content">
                    <div class="control has-icons-left has-icons-right">
                      <input
                        class="input is-large"
                        type="text"
                        placeholder=""
                      />
                      <span class="icon is-medium is-left">
                        <i class="fa fa-search"></i>
                      </span>
                      <span class="icon is-medium is-right">
                        <i class="fa fa-check"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      a: "",
    };
  },
  mounted() {
    console.log("mounted");
    var burger = document.querySelector(".burger");
    var menu = document.querySelector("#" + burger.dataset.target);
    burger.addEventListener("click", function () {
      burger.classList.toggle("is-active");
      menu.classList.toggle("is-active");
    });
  },
};
</script>

<style scoped>
html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
  font-size: 16px;
  line-height: 1.5;
  height: 100%;
  background: #ecf0f3;
}
nav.navbar {
  border-top: 4px solid #276cda;
  margin-bottom: 1rem;
}
.navbar-item.brand-text {
  font-weight: 300;
}
.navbar-item,
.navbar-link {
  font-size: 14px;
  font-weight: 700;
}
.columns {
  width: 100%;
  height: 100%;
  margin-left: 0;
}
.menu-label {
  color: #8f99a3;
  letter-spacing: 1.3;
  font-weight: 700;
}
.menu-list a {
  color: #0f1d38;
  font-size: 14px;
  font-weight: 700;
}
.menu-list a:hover {
  background-color: transparent;
  color: #276cda;
}
.menu-list a.is-active {
  background-color: transparent;
  color: #276cda;
  font-weight: 700;
}
.card {
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.18);
  margin-bottom: 2rem;
}
.card-header-title {
  color: #8f99a3;
  font-weight: 400;
}
.info-tiles {
  margin: 1rem 0;
}
.info-tiles .subtitle {
  font-weight: 300;
  color: #8f99a3;
}
.hero.welcome.is-info {
  background: #36d1dc;
  background: -webkit-linear-gradient(to right, #5b86e5, #36d1dc);
  background: linear-gradient(to right, #5b86e5, #36d1dc);
}
.hero.welcome .title,
.hero.welcome .subtitle {
  color: hsl(192, 17%, 99%);
}
.card .content {
  font-size: 14px;
}
.card-footer-item {
  font-size: 14px;
  font-weight: 700;
  color: #8f99a3;
}
.card-footer-item:hover {
}
.card-table .table {
  margin-bottom: 0;
}
.events-card .card-table {
  max-height: 250px;
  overflow-y: scroll;
}

@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
@import "https://fonts.googleapis.com/css?family=Open+Sans:300,400,700";
@import "https://unpkg.com/bulma@0.9.0/css/bulma.min.css";
</style>
